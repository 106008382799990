import React from 'react';

import styles from '../sass/components/page.module.scss';
import SvgClose from '../svg/close';
import Logo from '../svg/logo';
import Container from './container';
import Link from './link';

export const Page = ({ children, type }) => {
  const modificator = type
    ? styles[`page${type[0].toUpperCase() + type.slice(1)}`]
    : ''
  return (
    <section className={`${styles.page} ${modificator}`}>{children}</section>
  )
}

export const Header = ({ children, closeUrl = false, ...rest }) => (
  <header className={styles.page__header} {...rest}>
    <Container>
      <strong className={styles.page__header__title}>{children}</strong>
      <Link to="/" className={styles.page__header__logo}>
        <Logo />
      </Link>
      {closeUrl && (
        <Link to={closeUrl} className={styles.page__header__close}>
          <span>Close</span>
          <SvgClose />
        </Link>
      )}
    </Container>
  </header>
)

export const Content = ({ children, center = false }) => (
  <main
    className={`${styles.page__content} ${
      center ? styles.page__contentCentered : ''
    }`}
  >
    <Container>{children}</Container>
  </main>
)

export const ContentDesc = ({ children, className }) => (
  <p className={`${styles.page__content__desc} ${className}`}>{children}</p>
)

export const Footer = ({ children }) => (
  <footer className={styles.page__footer}>
    <Container>{children}</Container>
  </footer>
)

export const FooterLinkNext = ({ to, children, ...rest }) => (
  <Link to={to} className={styles.page__footer__next} {...rest}>
    {children}
  </Link>
)

export const FooterSubmit = ({ to, children, ...rest }) => (
  <button type="submit" className={styles.page__footer__next} {...rest}>
    {children}
  </button>
)

export const FooterLinkBack = ({ to, children, ...rest }) => (
  <Link to={to} className={styles.page__footer__back} {...rest}>
    {children}
  </Link>
)

export const TextPageHeader = ({ children, ...rest }) => (
  <div className={styles.page__textpageheader} {...rest}>
    {children}
  </div>
)
