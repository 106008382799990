import React from 'react';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import styles from '../sass/components/answer.module.scss';

export default () => (
  <Layout>
    <Seo
      title="Thanks! Your CZECH BEER AMBASSADOR Certificate is on its
            way!"
    />

    <div className={styles.answer}>
      <Page type="success">
        <Header closeUrl="/lessons" />
        <Content>
          <h1 className={`h2 less-growing text-transform-uppercase`}>
            Thanks! Your Czech beer Ambassador Certificate is on
            its&nbsp;way!
          </h1>
          <div className="page-text-container">
            <div className={`h6 ${styles.answer__text}`}>
              Visit our website for more stories about Pilsner&nbsp;Urquell.
            </div>
          </div>
        </Content>
        <Footer>
          <FooterLinkNext
            to="https://www.pilsnerurquell.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            GO TO WEBSITE
          </FooterLinkNext>
        </Footer>
      </Page>
    </div>
  </Layout>
)
